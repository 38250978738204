<template>
	<div v-if="!logged_user.id" row style="justify-content: center;">
		<LoginFields
			:custom_login='false'
		/>
	</div>
</template>
<script>
import LoginFields from "@/components/login/LoginFields.vue";
import { mapGetters } from "vuex";
export default ({
	name: 'Login',
	meta: {
		title: 'Alert Cascade - Login'
	},
	components: {
		LoginFields
	},
	data() {
		return {
		}
	},
	computed: {
		...mapGetters(["logged_user"]),
	},
})
</script>
